var exports = {};
// Utility functions
var PREFIXES = "Webkit Moz O ms".split(" ");
var FLOAT_COMPARISON_EPSILON = 0.001; // Copy all attributes from source object to destination object.
// destination object is mutated.

function extend(destination, source, recursive) {
  destination = destination || {};
  source = source || {};
  recursive = recursive || false;

  for (var attrName in source) {
    if (source.hasOwnProperty(attrName)) {
      var destVal = destination[attrName];
      var sourceVal = source[attrName];

      if (recursive && isObject(destVal) && isObject(sourceVal)) {
        destination[attrName] = extend(destVal, sourceVal, recursive);
      } else {
        destination[attrName] = sourceVal;
      }
    }
  }

  return destination;
} // Renders templates with given variables. Variables must be surrounded with
// braces without any spaces, e.g. {variable}
// All instances of variable placeholders will be replaced with given content
// Example:
// render('Hello, {message}!', {message: 'world'})


function render(template, vars) {
  var rendered = template;

  for (var key in vars) {
    if (vars.hasOwnProperty(key)) {
      var val = vars[key];
      var regExpString = "\\{" + key + "\\}";
      var regExp = new RegExp(regExpString, "g");
      rendered = rendered.replace(regExp, val);
    }
  }

  return rendered;
}

function setStyle(element, style, value) {
  var elStyle = element.style; // cache for performance

  for (var i = 0; i < PREFIXES.length; ++i) {
    var prefix = PREFIXES[i];
    elStyle[prefix + capitalize(style)] = value;
  }

  elStyle[style] = value;
}

function setStyles(element, styles) {
  forEachObject(styles, function (styleValue, styleName) {
    // Allow disabling some individual styles by setting them
    // to null or undefined
    if (styleValue === null || styleValue === undefined) {
      return;
    } // If style's value is {prefix: true, value: '50%'},
    // Set also browser prefixed styles


    if (isObject(styleValue) && styleValue.prefix === true) {
      setStyle(element, styleName, styleValue.value);
    } else {
      element.style[styleName] = styleValue;
    }
  });
}

function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

function isString(obj) {
  return typeof obj === "string" || obj instanceof String;
}

function isFunction(obj) {
  return typeof obj === "function";
}

function isArray(obj) {
  return Object.prototype.toString.call(obj) === "[object Array]";
} // Returns true if `obj` is object as in {a: 1, b: 2}, not if it's function or
// array


function isObject(obj) {
  if (isArray(obj)) {
    return false;
  }

  var type = typeof obj;
  return type === "object" && !!obj;
}

function forEachObject(object, callback) {
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      var val = object[key];
      callback(val, key);
    }
  }
}

function floatEquals(a, b) {
  return Math.abs(a - b) < FLOAT_COMPARISON_EPSILON;
} // https://coderwall.com/p/nygghw/don-t-use-innerhtml-to-empty-dom-elements


function removeChildren(el) {
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }
}

exports = {
  extend: extend,
  render: render,
  setStyle: setStyle,
  setStyles: setStyles,
  capitalize: capitalize,
  isString: isString,
  isFunction: isFunction,
  isObject: isObject,
  forEachObject: forEachObject,
  floatEquals: floatEquals,
  removeChildren: removeChildren
};
export default exports;