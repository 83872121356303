import _shifty from "shifty";
import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Lower level API to animate any kind of svg path
var shifty = _shifty;
var utils = _utils;
var Tweenable = shifty.Tweenable;
var EASING_ALIASES = {
  easeIn: "easeInCubic",
  easeOut: "easeOutCubic",
  easeInOut: "easeInOutCubic"
};

var Path = function Path(path, opts) {
  // Throw a better error if not initialized with `new` keyword
  if (!((this || _global) instanceof Path)) {
    throw new Error("Constructor was called without new keyword");
  } // Default parameters for animation


  opts = utils.extend({
    delay: 0,
    duration: 800,
    easing: "linear",
    from: {},
    to: {},
    step: function () {}
  }, opts);
  var element;

  if (utils.isString(path)) {
    element = document.querySelector(path);
  } else {
    element = path;
  } // Reveal .path as public attribute


  (this || _global).path = element;
  (this || _global)._opts = opts;
  (this || _global)._tweenable = null; // Set up the starting positions

  var length = (this || _global).path.getTotalLength();

  (this || _global).path.style.strokeDasharray = length + " " + length;
  this.set(0);
};

Path.prototype.value = function value() {
  var offset = this._getComputedDashOffset();

  var length = (this || _global).path.getTotalLength();

  var progress = 1 - offset / length; // Round number to prevent returning very small number like 1e-30, which
  // is practically 0

  return parseFloat(progress.toFixed(6), 10);
};

Path.prototype.set = function set(progress) {
  this.stop();
  (this || _global).path.style.strokeDashoffset = this._progressToOffset(progress);
  var step = (this || _global)._opts.step;

  if (utils.isFunction(step)) {
    var easing = this._easing((this || _global)._opts.easing);

    var values = this._calculateTo(progress, easing);

    var reference = (this || _global)._opts.shape || this || _global;
    step(values, reference, (this || _global)._opts.attachment);
  }
};

Path.prototype.stop = function stop() {
  this._stopTween();

  (this || _global).path.style.strokeDashoffset = this._getComputedDashOffset();
}; // Method introduced here:
// http://jakearchibald.com/2013/animated-line-drawing-svg/


Path.prototype.animate = function animate(progress, opts, cb) {
  opts = opts || {};

  if (utils.isFunction(opts)) {
    cb = opts;
    opts = {};
  }

  var passedOpts = utils.extend({}, opts); // Copy default opts to new object so defaults are not modified

  var defaultOpts = utils.extend({}, (this || _global)._opts);
  opts = utils.extend(defaultOpts, opts);

  var shiftyEasing = this._easing(opts.easing);

  var values = this._resolveFromAndTo(progress, shiftyEasing, passedOpts);

  this.stop(); // Trigger a layout so styles are calculated & the browser
  // picks up the starting position before animating

  (this || _global).path.getBoundingClientRect();

  var offset = this._getComputedDashOffset();

  var newOffset = this._progressToOffset(progress);

  var self = this || _global;
  (this || _global)._tweenable = new Tweenable();

  (this || _global)._tweenable.tween({
    from: utils.extend({
      offset: offset
    }, values.from),
    to: utils.extend({
      offset: newOffset
    }, values.to),
    duration: opts.duration,
    delay: opts.delay,
    easing: shiftyEasing,
    step: function (state) {
      self.path.style.strokeDashoffset = state.offset;
      var reference = opts.shape || self;
      opts.step(state, reference, opts.attachment);
    }
  }).then(function (state) {
    if (utils.isFunction(cb)) {
      cb();
    }
  });
};

Path.prototype._getComputedDashOffset = function _getComputedDashOffset() {
  var computedStyle = window.getComputedStyle((this || _global).path, null);
  return parseFloat(computedStyle.getPropertyValue("stroke-dashoffset"), 10);
};

Path.prototype._progressToOffset = function _progressToOffset(progress) {
  var length = (this || _global).path.getTotalLength();

  return length - progress * length;
}; // Resolves from and to values for animation.


Path.prototype._resolveFromAndTo = function _resolveFromAndTo(progress, easing, opts) {
  if (opts.from && opts.to) {
    return {
      from: opts.from,
      to: opts.to
    };
  }

  return {
    from: this._calculateFrom(easing),
    to: this._calculateTo(progress, easing)
  };
}; // Calculate `from` values from options passed at initialization


Path.prototype._calculateFrom = function _calculateFrom(easing) {
  return shifty.interpolate((this || _global)._opts.from, (this || _global)._opts.to, this.value(), easing);
}; // Calculate `to` values from options passed at initialization


Path.prototype._calculateTo = function _calculateTo(progress, easing) {
  return shifty.interpolate((this || _global)._opts.from, (this || _global)._opts.to, progress, easing);
};

Path.prototype._stopTween = function _stopTween() {
  if ((this || _global)._tweenable !== null) {
    (this || _global)._tweenable.stop();

    (this || _global)._tweenable = null;
  }
};

Path.prototype._easing = function _easing(easing) {
  if (EASING_ALIASES.hasOwnProperty(easing)) {
    return EASING_ALIASES[easing];
  }

  return easing;
};

exports = Path;
export default exports;