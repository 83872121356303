import _shape from "./shape";
import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Square shaped progress bar
// Note: Square is not core part of API anymore. It's left here
//       for reference. square is not included to the progressbar
//       build anymore
var Shape = _shape;
var utils = _utils;

var Square = function Square(container, options) {
  (this || _global)._pathTemplate = "M 0,{halfOfStrokeWidth}" + " L {width},{halfOfStrokeWidth}" + " L {width},{width}" + " L {halfOfStrokeWidth},{width}" + " L {halfOfStrokeWidth},{strokeWidth}";
  (this || _global)._trailTemplate = "M {startMargin},{halfOfStrokeWidth}" + " L {width},{halfOfStrokeWidth}" + " L {width},{width}" + " L {halfOfStrokeWidth},{width}" + " L {halfOfStrokeWidth},{halfOfStrokeWidth}";
  Shape.apply(this || _global, arguments);
};

Square.prototype = new Shape();
Square.prototype.constructor = Square;

Square.prototype._pathString = function _pathString(opts) {
  var w = 100 - opts.strokeWidth / 2;
  return utils.render((this || _global)._pathTemplate, {
    width: w,
    strokeWidth: opts.strokeWidth,
    halfOfStrokeWidth: opts.strokeWidth / 2
  });
};

Square.prototype._trailString = function _trailString(opts) {
  var w = 100 - opts.strokeWidth / 2;
  return utils.render((this || _global)._trailTemplate, {
    width: w,
    strokeWidth: opts.strokeWidth,
    halfOfStrokeWidth: opts.strokeWidth / 2,
    startMargin: opts.strokeWidth / 2 - opts.trailWidth / 2
  });
};

exports = Square;
export default exports;