import _shape from "./shape";
import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Line shaped progress bar
var Shape = _shape;
var utils = _utils;

var Line = function Line(container, options) {
  (this || _global)._pathTemplate = "M 0,{center} L 100,{center}";
  Shape.apply(this || _global, arguments);
};

Line.prototype = new Shape();
Line.prototype.constructor = Line;

Line.prototype._initializeSvg = function _initializeSvg(svg, opts) {
  svg.setAttribute("viewBox", "0 0 100 " + opts.strokeWidth);
  svg.setAttribute("preserveAspectRatio", "none");
};

Line.prototype._pathString = function _pathString(opts) {
  return utils.render((this || _global)._pathTemplate, {
    center: opts.strokeWidth / 2
  });
};

Line.prototype._trailString = function _trailString(opts) {
  return this._pathString(opts);
};

exports = Line;
export default exports;