import _shape from "./shape";
import _circle from "./circle";
import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Semi-SemiCircle shaped progress bar
var Shape = _shape;
var Circle = _circle;
var utils = _utils;

var SemiCircle = function SemiCircle(container, options) {
  // Use one arc to form a SemiCircle
  // See this answer http://stackoverflow.com/a/10477334/1446092
  (this || _global)._pathTemplate = "M 50,50 m -{radius},0" + " a {radius},{radius} 0 1 1 {2radius},0";
  (this || _global).containerAspectRatio = 2;
  Shape.apply(this || _global, arguments);
};

SemiCircle.prototype = new Shape();
SemiCircle.prototype.constructor = SemiCircle;

SemiCircle.prototype._initializeSvg = function _initializeSvg(svg, opts) {
  svg.setAttribute("viewBox", "0 0 100 50");
};

SemiCircle.prototype._initializeTextContainer = function _initializeTextContainer(opts, container, textContainer) {
  if (opts.text.style) {
    // Reset top style
    textContainer.style.top = "auto";
    textContainer.style.bottom = "0";

    if (opts.text.alignToBottom) {
      utils.setStyle(textContainer, "transform", "translate(-50%, 0)");
    } else {
      utils.setStyle(textContainer, "transform", "translate(-50%, 50%)");
    }
  }
}; // Share functionality with Circle, just have different path


SemiCircle.prototype._pathString = Circle.prototype._pathString;
SemiCircle.prototype._trailString = Circle.prototype._trailString;
exports = SemiCircle;
export default exports;